<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
    :display="height"
    :tickDisplay="tickDisplay"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ChartDataLabels)

export default {
  name: 'MiladyBarChart',
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Object,
      required: true
    },
    tickDisplay: {
      type: Boolean,
      default: true
    },
    displayLegend: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      chartOptions: {
        scales: {
          y: {
            beginAtZero: true
          },
          x: {
            ticks: {
              display: this.tickDisplay,
            },
          },
        },
        responsive: true,
        plugins: {
          legend: {
            display: this.displayLegend,
          },
          // Change options for ALL labels of THIS CHART
          datalabels: {
            color: '#E0E0E0'
          },
        },
        maintainAspectRatio: false,
      }
    }
  }
}
</script>
